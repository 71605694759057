import i18next from 'i18next';
import axios from 'axios';

import LanguageDetector from 'i18next-browser-languagedetector';

import locI18next from 'loc-i18next';

import en from './assets/locales/en/translation.json';
import ru from './assets/locales/ru/translation.json';
import uk from './assets/locales/uk/translation.json';

i18next
  .use(LanguageDetector)
  .on('languageChanged', lang => {
    document.documentElement.lang = lang;
  })
  .init({
    fallbackLng: ['en', 'ru', 'uk'],
    detection: {
      order: ['localStorage', 'htmlTag'],
      lookupLocalStorage: 'lang',
      lookupQuerystring: 'lang',
    },
    resources: {
      en: {
        translation: en,
      },
      ru: {
        translation: ru,
      },
      uk: {
        translation: uk,
      },
    },
  })
  .then(function () {
    updateContent();
  });

function updateContent() {
  const localize = locI18next.init(i18next, {
    useOptionsAttr: true,
  });
  localize('html');
}
function updateUrlLanguage(lang) {
  let url = new URL(window.location);
  url.searchParams.set('lang', lang);
  window.history.pushState({}, '', url);
}
function changeLng(selectedLang) {
  i18next.changeLanguage(selectedLang).then(t => {
    updateContent();
    updateUrlLanguage(selectedLang);
  });
}

const languages = {
  'English (ENG)': 'en',
  'Русский (RUS)': 'ru',
  'Українська (UKR)': 'uk',
};
const onChangeLanguage = () => {
  const telegramURLs = document.querySelectorAll('[data-service="Telegram"]');
  telegramURLs.forEach(url => {
    url.href =
      i18next.language === 'ru'
        ? process.env.TELEGRAM_URL
        : process.env.TELEGRAM_URL_EN;
  });

  const twitterComponent = document.getElementById('twitter_wrapper');
  i18next.language === 'en'
    ? (twitterComponent.style.display = 'flex')
    : (twitterComponent.style.display = 'none');
  const votingCounter = document.getElementById('voting_counter');
  i18next.language === 'en'
    ? (votingCounter.style.right = '8px')
    : (votingCounter.style.right = '-8px');
};
i18next.on('languageChanged', onChangeLanguage);

const switchLanguage = () => {
  const options = document.querySelectorAll('.option');
  options.forEach(option => {
    option.addEventListener('click', () => {
      const optionText = option.textContent.trim();
      const selectedLang = languages[optionText];
      changeLng(selectedLang);
      onChangeLanguage();
    });
  });
};

// Функция для получения параметров из URL
const getUrlParameter = name => {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  const results = regex.exec(window.location.search);
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

const changeLanguageIfNeeded = () => {
  const langFromUrl = getUrlParameter('lang');
  if (langFromUrl) {
    if (!i18next.options.fallbackLng.includes(langFromUrl)) {
      updateUrlLanguage('en');
    }
    i18next.changeLanguage(langFromUrl);
    updateContent();
  }
};

const checkReferral = () => {
  const currentLanguage = i18next.language;

  const registrationLink = `${process.env.WALLET_URL}auth/user-registration`;

  const addLink = (regLink, linkDocument) => {
    linkDocument.forEach(link => {
      link.href = regLink;
    });
  };
  const getUrlWithLang = (baseUrl, refAccountAddress) => {
    let url;
    const langFromUrl = getUrlParameter('lang');
    if (langFromUrl) {
      url = `${baseUrl}?lang=${langFromUrl}`;
    } else {
      url = `${baseUrl}?lang=${currentLanguage}`;
    }

    if (refAccountAddress) {
      url += `&ref=${refAccountAddress.trim()}`;
    }
    return url;
  };
  const walletLinkButtons = document.querySelectorAll(
    '[data-service="Wallet"]'
  );
  const walletRegistrationLinkButtons = document.querySelectorAll(
    '[data-service="WalletRegistration"]'
  );
  const refInUrl = getUrlParameter('ref');
  if (refInUrl) {
    sessionStorage.setItem('refAccountAddress', refInUrl);
  }
  const hash = window.location.hash.includes('#faq') ? '#faq' : '';
  const refAccountAddress =
    refInUrl || sessionStorage.getItem('refAccountAddress');
  const baseUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${hash}`;
  const newUrl = getUrlWithLang(baseUrl, refAccountAddress);
  history.pushState(null, null, newUrl);

  const getRegistrationLink = refAccountAddress => {
    let regLink = `${registrationLink}?lang=${currentLanguage}`;
    if (refAccountAddress) {
      regLink += `&referrer=${refAccountAddress}`;
    }
    return regLink;
  };

  const regLink = getRegistrationLink(refAccountAddress);
  if (refAccountAddress) {
    addLink(regLink, walletLinkButtons);
  }
  addLink(regLink, walletRegistrationLinkButtons);
};

const routeCheck = locationPath => {
  switch (locationPath) {
    case '/':
      return './index.html';
    case '/whitepaper.html':
      return './whitepaper.html';

    case '/404.html':
      return './404.html';

    default:
      return './404.html';
  }
};

let locationHandler = async event => {
  event.preventDefault();
  let locationPath = window.location.pathname;
  let route = routeCheck(locationPath);

  if (route === './404.html' && locationPath !== '/404.html') {
    window.location.pathname = '/404.html';
    return;
  }
  if (route === './index.html' && locationPath !== '/') {
    window.location.pathname = '/';
  }

  const hash = window.location.hash;
  if (hash.includes('faq')) {
    const faqMid = document.getElementById('faq');
    if (!faqMid) {
      return;
    }
    faqMid.scrollIntoView();
  } else {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
};

document.addEventListener('DOMContentLoaded', event => {
  locationHandler(event);
  changeLanguageIfNeeded();
  switchLanguage();
  checkReferral();
});
